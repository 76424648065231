import React, { useEffect, useState } from 'react'
import { KTCard } from '../../../_metronic/helpers'
import { ProductsListSearchComponent } from '../../modules/products/products-list/components/header/ProductsListSearchComponent'
import { useAuth } from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import { toast } from 'react-toastify'
import CommonPagination from '../../../_metronic/helpers/components/CommonPagination'

function TotalBuyersshow() {
    const API_URL = process.env.REACT_APP_KT_API_URL
    const tokenString: any = localStorage.getItem('kt-auth-react-v')
    const token = JSON.parse(tokenString)
    const [productdata, setProductData] = useState<any>([])
    const [searchBar, setSearchbar] = useState({ search: '' });
    let { currentUser } = useAuth();
    const [total_pages, setTotal_pages] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState(1);
    // const perPage = 10; // number of items per pagen;
    const [pageSize, setPageSize] = useState<any>(10)
    const [total_items, setTotal_Items] = useState<any>(null)

      // toast center align
      const toastError = (errorMessage) => {
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000
        });
      };
    

    // total_products
    const total_buyers = async () => {
        try {
            let params = new URLSearchParams();
            if (currentUser?.roles && currentUser.roles.length > 0) {
                const userId = currentUser?.id
                const userType = currentUser.roles[0]?.id;
                if (userType !== null && userType !== undefined) {
                    params.append("user_type_id", userType?.toString())
                    params.append("userId", userId?.toString())
                    if (searchBar.search !== "") {
                        params.append("search", searchBar?.search?.trim())
                    }
                }
            }
            const queryString = params.toString();
            const response = await fetch(`${API_URL}/dashboard/GetAllBuyer?page=${currentPage}&limit=${pageSize}&${queryString}`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = await response.json()
            if (!response.ok) {
                toastError(data?.message)
            }
          
            const itemsWithSerialNumbers = data?.data?.items.map((item, index) => ({
                ...item,
                serialNumber: (currentPage - 1) * pageSize + index + 1
            }));
            setProductData(itemsWithSerialNumbers)
            setTotal_pages(data?.data?.meta?.totalPages)
            setTotal_Items(data?.data?.meta?.totalItems)
            // setProductData(data?.data?.items)
        } catch (error) {
            // console.error(error)
        }
    }

    useEffect(() => {
        total_buyers()
    }, [searchBar, currentPage,pageSize])

    // const handlePageChange = (selectedPage) => {
    //     setCurrentPage(selectedPage?.selected + 1); // Update currentPage state with the selected page number
    // };
    
    const handlePageChange = (event,selectedPage) => {
        setCurrentPage(selectedPage); 
    };


    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value)
        setCurrentPage(1); 
      }
    const getDatatotalbuyers: (value: any) => void = (value) => {
        setSearchbar((search) => ({ ...search, search: value }))
    };

    return (
        <React.Fragment>
            <KTCard>
                <div className='card-header border-0 pt-6 pb-6'>
                    <ProductsListSearchComponent getDatatotalbuyers={getDatatotalbuyers} />
                    <div className='table-responsive pt-6 w-100'>
                        <table className='table align-middle table-bordered table-row-dashed gy-5 dataTable no-footer rounded'>
                            <thead>
                                <tr className='fw-bold text-dark text-uppercase'>
                                    <th className='ps-4 w-60px'>S.No</th>
                                    <th>Name</th>
                                    <th>email</th>
                                    <th>mobile number</th>
                                    <th>created </th>
                                    <th>updated </th>
                                    <th>status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {productdata && productdata.length > 0 ? productdata?.map?.((item, index) => {
                                    const created_at = new Date(item.created_at).toISOString().split('T')[0].split('-').reverse().join('-');
                                    const updated_at = new Date(item.updated_at).toISOString().split('T')[0].split('-').reverse().join('-');
                                    return (
                                        <tr key={item?.serialNumber}>
                                            <td className='ps-4 w-60px'>{item?.serialNumber}</td>
                                            <td>{item.first_name} {item.last_name}</td>
                                            <td>{item.email}</td>
                                            <td>{item.mobile_number}</td>
                                            <td>{created_at}</td>
                                            <td>{updated_at}</td>
                                            <td>
                                                <div className={`badge ${item.status === true ? "badge-light-success" : "badge-light-danger"} fw-bolder`}>
                                                    {item.status === true ? "Active" : "Deactivate"}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                                    : <tr>
                                        <td colSpan={14}>
                                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                No matching records found
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    <div style={{width:"100%"}}>
                    {
                        productdata && productdata?.length > 0 &&
                        // <ReactPaginate
                        //     previousLabel={"previous"}
                        //     nextLabel={"next"}
                        //     breakLabel={"..."}
                        //     pageCount={total_pages}
                        //     marginPagesDisplayed={1}
                        //     pageRangeDisplayed={2}
                        //     onPageChange={handlePageChange}
                        //     containerClassName={"pagination justify-content-end mt-2 mb-3 ms-auto"}
                        //     pageClassName={"page-item"}
                        //     pageLinkClassName={"page-link"}
                        //     previousClassName={"page-item"}
                        //     previousLinkClassName={"page-link"}
                        //     nextClassName={"page-item"}
                        //     nextLinkClassName={"page-link"}
                        //     breakClassName={"page-item"}
                        //     breakLinkClassName={"page-link"}
                        //     activeClassName={"active"}
                        // />
                        <CommonPagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalItems={total_items}
                        totalPages={total_pages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        title={'Buyers'}
                      />
                    }
                </div>
                </div>
            </KTCard>
        </React.Fragment >
    )
}

export default TotalBuyersshow