import React, { useEffect, useState } from 'react'
import { KTCard } from '../../../_metronic/helpers'
import { ProductsListSearchComponent } from '../../modules/products/products-list/components/header/ProductsListSearchComponent'
import { useAuth } from '../../modules/auth'
import ReactPaginate from 'react-paginate'
import { Tooltip } from 'react-tooltip'
import { toast } from 'react-toastify'
import CommonPagination from '../../../_metronic/helpers/components/CommonPagination'
let productindexNumber = 0
function Normalprice_atrisk() {
    const API_URL = process.env.REACT_APP_KT_API_URL
    const tokenString: any = localStorage.getItem('kt-auth-react-v')
    const token = JSON.parse(tokenString)
    const [productdata, setProductData] = useState<any>([])
    const [searchBar, setSearchbar] = useState({ search: '' });
    let { currentUser } = useAuth();
    const [total_pages, setTotal_pages] = useState<any>(null)
    const [total_items, setTotal_Items] = useState<any>(null)
    const [currentPage, setCurrentPage] = useState(1);
    // const perPage = 10; // number of items per pagen;
    // const total_pagess = Math.ceil(total_pages / perPage)
    const [pageSize, setPageSize] = useState<any>(10)


      // toast center align
  const toastError = (errorMessage) => {
    toast.error(errorMessage, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000
    });
  };

    // total_products at risk
    const productsRisk = async () => {
        try {
            let params = new URLSearchParams();
            if (currentUser?.roles && currentUser.roles.length > 0) {
                const userId = currentUser?.id
                const userType = currentUser.roles[0]?.id;
                if (userType !== null && userType !== undefined) {
                    params.append("user_type_id", userType?.toString())
                    params.append("userId", userId?.toString())
                    if (searchBar.search !== "") {
                        params.append("search", searchBar?.search?.trim())
                    }
                }
                params.append("fillterProductAtRisk", true.toString())
            }
            const queryString = params.toString();
            const response = await fetch(`${API_URL}/dashboard/NormalPriceProduct?page=${currentPage}&limit=${pageSize}&${queryString}`, {
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
            })
            const data = await response.json()
            if (!response.ok) {
                toastError(data?.message)
            }
            setProductData(data?.data?.items)
            setTotal_pages(data?.data?.meta?.totalPages)
            setTotal_Items(data?.data?.meta?.totalItems)
        } catch (error) {
            // console.error(error)
        }
    }

    useEffect(() => {
        productsRisk()
        productindexNumber = 0
    }, [searchBar, currentPage,pageSize])

    const getDatarisk_products: (value: any) => void = (value) => {
        productindexNumber = 0
        setSearchbar((search) => ({ ...search, search: value }))
    };
    const handlePageChange = (event, selectedPage) => {
        productindexNumber = 0
        // setCurrentPage(selectedPage.selected + 1); // Update currentPage state with the selected page number
        setCurrentPage(selectedPage)
    };

    const handlePageSizeChange = (event) => {
        setPageSize(event.target.value)
        setCurrentPage(1); 
        productindexNumber=0
      }


    return (
        <React.Fragment>
            <KTCard>
                <div className='card-header border-0 pt-6 pb-6'>
                <Tooltip id='my-tooltip' />
                    <ProductsListSearchComponent getDataTotalProducts={getDatarisk_products} />
                    <div className='table-responsive pt-6 w-100'>
                        <table className='table align-middle table-bordered table-row-dashed gy-5 dataTable no-footer rounded'>
                            <thead>
                                <tr className='fw-bold text-dark text-uppercase'>
                                    <th className='ps-4 w-60px'>S.No</th>
                                    {/* <th>Product name</th> */}
                                    <th>ITEM SKU</th>
                                    <th>SKU DESCRIPTION</th>
                                    <th className='channel_name' style={{ padding: 10 }}>
                                        channel name
                                    </th>
                                    <th style={{ padding: 10 }}>BUYER CODE</th>
                                    {/* <th className='text-center'>Sales Date</th> */}
                                    <th>status</th>
                                    <th>
                                        <div>
                                        Weighted Avg. Cost
                                            {/* <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Weighted Avg. Cost Price'
                                            >
                                                <svg xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1' >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        {' '}
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span> */}
                                        </div>
                                    </th>
                                    <th>
                                        {' '}
                                        <div>
                                            List Price (USD)
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Channel Currency'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>
                                        <div>
                                            Channel Cost Price
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Price Listed on Channel'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>{' '}
                                                </svg>
                                            </span>
                                        </div>
                                    </th>
                                    <th>
                                        <span className='d-block'>
                                            Sell Price
                                            <span
                                                className='svg-icon svg-icon-primary svg-icon-2x ms-2 p-0 cursor-pointer'
                                                data-tooltip-id='my-tooltip'
                                                data-tooltip-content='Price after channel margin term &amp; return'
                                            >
                                                <svg
                                                    xmlns='http://www.w3.org/2000/svg'
                                                    width='24px'
                                                    height='24px'
                                                    viewBox='0 0 24 24'
                                                    version='1.1'
                                                >
                                                    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                                        <rect x='0' y='0' width='24' height='24'></rect>
                                                        <circle fill='#000000' opacity='0.2' cx='12' cy='12' r='10'></circle>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='10'
                                                            width='2'
                                                            height='7'
                                                            rx='1'
                                                        ></rect>
                                                        <rect
                                                            fill='#000000'
                                                            opacity='0.5'
                                                            x='11'
                                                            y='7'
                                                            width='2'
                                                            height='2'
                                                            rx='1'
                                                        ></rect>
                                                    </g>
                                                </svg>
                                            </span>
                                        </span>
                                    </th>
                                    <th>
                                        <span className='d-block'>Net Profit Amount </span>
                                    </th>
                                    <th>
                                        <span className='d-block'>Net profit percent </span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                    productdata && productdata.length > 0 ?
                                        productdata?.map?.((items, indexx) => {
                                            return items?.product?.channels?.map((channel, index) => {
                                                (productindexNumber = productindexNumber + 1)
                                                return (
                                                    <React.Fragment>
                                                        {
                                                        // channel_calculations?.price_status?.toLowerCase() === "at bonus" &&
                                                            <tr key={(pageSize * (currentPage - 1) + productindexNumber)}>
                                                                <td className='ps-4'>{(pageSize * (currentPage - 1) + productindexNumber)}</td>
                                                                {/* <td>{items.name}</td> */}
                                                                <td>{items.product.sku}</td>
                                                                <td>{items.product.description}</td>
                                                                <td key={index}>{channel.channel_name}</td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    {
                                                                        items?.product?.buyer_codes && items?.product?.buyer_codes?.length > 0 ? items?.product?.buyer_codes?.map((data, index) => (
                                                                            <div key={index}>
                                                                                {data?.buyerCodes?.code}
                                                                                {index !== items?.product?.buyer_codes?.length - 1 && ' ,'}
                                                                            </div>
                                                                        ))
                                                                            : "N/A"
                                                                    }
                                                                </td>
                                                                {/* <td style={{ minWidth: 200, textAlign: "center" }}>N/A</td> */}
                                                                <td>
                                                                    <span className={`badge ${items.price_status === "At Risk" ? "badge-light-danger" : "badge-light-info"}`}>
                                                                        {items.price_status}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <div className='custom-width-set'>
                                                                        <div className='input-group'>
                                                                            <div className='input-group-prepend'>
                                                                                <span className='input-group-text edit-fields' id='inputGroupPrepend'>
                                                                                    {
                                                                                        new Intl.NumberFormat('en-US', {
                                                                                            style: 'currency',
                                                                                            currency: channel.currency_code.split('-')[0],
                                                                                        }).formatToParts()[0].value
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <input
                                                                                type='number'
                                                                                name='list_price'
                                                                                value={items?.product.cost_price}
                                                                                className='form-control edit-fields w-50px'
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                {/* <td key={index}>{channel.channel_name}</td> */}
                                                                <td>
                                                                    <div className='input-group'>
                                                                        <div className='input-group-prepend'>
                                                                            <span
                                                                                className='input-group-text edit-fields'
                                                                                id='inputGroupPrepend'
                                                                            >
                                                                                $
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control edit-fields w-50px'
                                                                            value={items.list_price}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className='input-group'>
                                                                        <div className='input-group-prepend'>
                                                                            <span
                                                                                className='input-group-text edit-fields'
                                                                                id='inputGroupPrepend'
                                                                            >
                                                                                $
                                                                            </span>
                                                                        </div>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control edit-fields w-50px'
                                                                            value={items.channel_cost_price}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.sell_price}
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.net_profit_amount}
                                                                </td>
                                                                <td>
                                                                    <span>$</span>
                                                                    {items.net_profit_percent}
                                                                </td>
                                                            </tr>
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        })
                                        : <tr>
                                            <td colSpan={14}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    No matching records found
                                                </div>
                                            </td>
                                        </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {/* <div className='d-flex position-absolute' style={{ right: "36px", top: "36px" }}>
                        <div className="form-check me-5">
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={normalPriceChecked} onChange={handleNormalPriceToggle} />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Normal Price/At Risk
                            </label>
                        </div>
                        <div className="form-check"  >
                            <input className="form-check-input" type="checkbox" id="flexCheckDefault1" checked={salePriceChecked} onChange={handleSalePriceToggle} />
                            <label className="form-check-label" htmlFor="flexCheckDefault1">
                                Sale Price/At Risk
                            </label>
                        </div>
                    </div> */}
                      <div style={{width:"100%"}}>
                    {
                        productdata && productdata?.length > 0 &&
                        // <ReactPaginate
                        //     previousLabel={"previous"}
                        //     nextLabel={"next"}
                        //     breakLabel={"..."}
                        //     pageCount={total_pagess}
                        //     marginPagesDisplayed={2}
                        //     pageRangeDisplayed={3}
                        //     onPageChange={handlePageChange}
                        //     containerClassName={"pagination justify-content-end mt-2 mb-3 ms-auto"}
                        //     pageClassName={"page-item"}
                        //     pageLinkClassName={"page-link"}
                        //     previousClassName={"page-item"}
                        //     previousLinkClassName={"page-link"}
                        //     nextClassName={"page-item"}
                        //     nextLinkClassName={"page-link"}
                        //     breakClassName={"page-item"}
                        //     breakLinkClassName={"page-link"}
                        //     activeClassName={"active"}
                        // />

                        <CommonPagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalItems={total_items}
                        totalPages={total_pages}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        title={'results'}
                      />
                    }
                     </div>
                </div>
            </KTCard>

        </React.Fragment >
    )
}

export default Normalprice_atrisk