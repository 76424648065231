import {useQueryClient, useMutation} from 'react-query'
import {QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {deleteSelectedCurrency} from '../../core/_requests'
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {useAuth} from "../../../../auth";
import {toast} from "react-toastify";
import {handleError} from "../../../../../../_metronic/helpers/crud-helper/handle-error";

const MySwal = withReactContent(Swal)
const CurrencyListGrouping = () => {
    const {selected, clearSelected} = useListView()
    const queryClient = useQueryClient()
    const {query} = useQueryResponse()
    const {currentUser} = useAuth()

    const deleteSelectedItems = useMutation(() => deleteSelectedCurrency(selected), {
        // 💡 response of the mutation is passed to onSuccess
        onSuccess: () => {
            // ✅ update detail view directly
            queryClient.invalidateQueries([`${QUERIES.CURRENCY_LIST}-${query}`])
            clearSelected()
            toast.success('Currency is deleted successfully')
        },
        onError: (ex: any) => {
            handleError(ex);
        }
    })

    return (
        <div className='d-flex justify-content-end align-items-center'>
            <div className='fw-bolder me-5'>
                <span className='me-2'>{selected.length}</span> Selected
            </div>

            {currentUser?.hasPermission('can-hide-product') &&
                <button
                    type='button'
                    className='btn btn-danger'
                    onClick={async () => {
                        MySwal.fire({
                            icon: 'warning',
                            title: 'Are you sure you want to hide selected Currency?',
                            showCancelButton: true
                        }).then(async (value) => {
                            if (value.isConfirmed) {
                                // console.log('Hide Currency')
                                // await deleteSelectedItems.mutateAsync();
                            }
                        })
                    }}
                >
                    Hide Selected
                </button>
            }

            {/*{currentUser?.hasPermission('can-delete-product') &&*/}
            {/*    <button*/}
            {/*        type='button'*/}
            {/*        className='btn btn-danger'*/}
            {/*        onClick={async () => {*/}
            {/*            MySwal.fire({*/}
            {/*                icon: 'warning',*/}
            {/*                title: 'Are you sure you want to delete selected Currency?',*/}
            {/*                showCancelButton: true*/}
            {/*            }).then(async (value) => {*/}
            {/*                if (value.isConfirmed) {*/}
            {/*                    await deleteSelectedItems.mutateAsync();*/}
            {/*                }*/}
            {/*            })*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        Delete Selected*/}
            {/*    </button>*/}
            {/*}*/}
        </div>
    )
}

export {CurrencyListGrouping}
